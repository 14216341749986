import logo from './logo/logo.png'
import logoWhite from './logo/logoWhite.png'
import facebook from './logo/facebook.svg'
import instagram from './logo/instagram.svg'
import whatsapp from './logo/whatsapp.svg'
import facebookAlt from './logo/facebook.png'
import instagramAlt from './logo/instagram.png'
import phonePe from './logo/phonePe.png'
import razorPay from './logo/razorPay.png'

import back from './illus/back.png'
import back1 from './illus/back2.png'
import back2 from './illus/back3.png'
import back3 from './illus/back4.png'
import quotes from './illus/quotes.svg'
import Location from './illus/location.png'
import Locations from './illus/locations.png'
import Login from './illus/login.png'
import SignUp from './illus/signUp.png'
import Card from './illus/card.png'

import noImage from './placeholders/noImage.png'
import Profile from './placeholders/profile.png'
import SubscriptionInfo from './placeholders/subscriptionInfo.png'

const main = {
  logo,
  logoWhite,
  facebook,
  instagram,
  whatsapp,
  facebookAlt,
  instagramAlt,
  phonePe,
  razorPay,
  back,
  back1,
  back2,
  back3,
  quotes,
  Location,
  Locations,
  Login,
  SignUp,
  Card,
  noImage,
  Profile,
  SubscriptionInfo
}

export default main
