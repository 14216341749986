const paymentPlatforms = [
  {
    name: 'razorPay',
    showName: 'Razor Pay'
  }
  // {
  //   name: 'phonePe'
  // ,
  //   showName: 'PhonePe'
  // }
]

export default paymentPlatforms
